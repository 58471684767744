* {
    color: white;
}

.intro {
    margin-top: 200px;
    margin-bottom: 200px;
}

.intro-page {
    margin-top:200px;
}

.intro-button{
    float: right;
    margin-right:125px;
    border: none;
    width: 300px;
    height: 100px;
    font-size: 30px;
    background-color:RGBA(40,80,37,0.64);
    font-family: 'Vollkorn SC', serif;
}

.intro-link:hover{
    color: #9d8465;
    cursor: pointer;
}

.intro-header {
    padding: 120px;
    font-size: 50px;
    font-family: 'Vollkorn SC', serif;
}

.arrow-scroll {
    margin-top: 150px;
    display: flex;
}

#myVideo {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: none;
    z-index: -100;
  }


@media (min-aspect-ratio: 16/9) {
    #myVideo {
        width:100%;
        height: auto;
    }
}

@media (max-aspect-ratio: 16/9) {
    #myVideo { 
        width:auto;
        height: 100%;
    }
}

@media (max-width: 760px) {
    #myVideo {
        display: none;
    }

    #mobile-background {
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: none;
        z-index: -100;
    }
    /* .intro {
        background: url('mobile3.jpg') no-repeat center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    } */
}

@media screen and (min-width: 320px) {
    .intro-button {
        width: 150px;
        font-size: 20px;
        float: none;
        margin-right: 0px;
        margin-left: 10px
    }

    .intro-header {
        padding: 20px;
        font-size: 35px;
        text-shadow: 2px 2px RGBA(40,80,37,0.80);
    }

    .arrow-scroll {
        margin-top: 70px;
        margin-left: 50px;
    }
}

@media screen and (min-width: 760px) {
    #mobile-background {
        display: none;
    }
    .intro {
        margin-top: 350px;
        margin-bottom: 350px;
    }

    .intro-button{
        margin-right: 80px;
        float: right;
        width: 300px;
        height: 100px;
        font-size: 30px;
    }

    .arrow-scroll {
        margin-top: 300px;
        margin-left: 50px;
    }
    

}

@media screen and (min-width: 1000px) {
    .intro-button{
        margin-right: 150px;
        float: right;
        width: 300px;
        height: 100px;
        font-size: 30px;
    }

    .intro-header {
        padding: 120px;
        font-size: 50px;
    }

    .arrow-scroll {
        margin-top: 150px;
        display: flex;
    }

}