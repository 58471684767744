.impact-page {
    background: url(./background.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute; 
    width: 120%;
}

.max {
    width: 100%;
}

.impact-full {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 300px;
    padding-top: 100px; 
    font-size: 1em;
    max-height: 620px;
}

.impact-header-main {
    font-family: 'Vollkorn SC', serif;
    text-align: center;
    font-size: 2em;
    text-shadow: 2px 2px RGBA(40,80,37,0.70);
    margin: 0;
}

.info-scroll {
    width: 320px;
    max-height: 500px;
}

.slick-slider {
    width: 320px;
    margin: auto;
}

.slick-dots {
    display: none;
}

.impact-full p{
    background-color:RGBA(40,80,37,0.9);
    padding: 25px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
}

.impact-list {
    flex-wrap: wrap;
    font-family: 'Montserrat', sans-serif;
}

.impact-list h3 {
    text-align: center;
    font-size: 2em;
    text-shadow: 2px 2px RGBA(40,80,37,0.70);
    margin: 0;
}

.main-list {
    background-color:RGBA(40,80,37,0.9);
    width: 300px;
    padding:10px;
    padding-left: 25px;
    margin: auto;
    margin-bottom: 50px;
    border-radius: 10px;
}

.solution-link {
    margin-left: 175px;
    text-decoration: none;
}


@media screen and (min-width: 480px) {
    .impact-full {
        flex-direction: column;
        width: 450px;
        font-size: 1em;
    }

    .slick-slider {
        width: 425px;
    }

    .main-list {
        width: 400px;
    }
}

@media screen and (min-width: 765px) {
    .impact-full {
        width: 650px;
    }
    
    /* .impact-full h2 {
        text-align: center;
        margin-top: 125px;
        margin-bottom: 0;
        font-size: 60px;
        text-shadow: 2px 3px RGBA(40,80,37,0.70);
    }

    .solution-h3 {
        margin-top: 30px;
        margin-bottom: -30px;
        font-size: 60px;
    }

    .impact-full p {
        font-size: 18px;
    } 

    .impact-list {
        justify-content: flex-end;
        width: 36%;
        height: 20%;
        margin-top: 125px;
    }

    .main-list {
        height: 600px;
        margin-top: 50px;
    } */
}

@media screen and (min-width: 1000px) {
    .max {
        display: flex;
        width: 100%;
    }

    .impact-page {
        display: flex;
        height: 125%;
    }
    
    .impact-header-main {
        font-size: 2.5em;
    }

    .impact-full {
        margin-top: 300px;
        width: 400px;
        margin-left: 20px;
        padding-top: 0px; 
    }

    .solution-h3 {
        font-size: 2.5em;
    }

    .impact-list {
        margin-top: 300px;
        margin-right: 45px;
    }

    .main-list {
        height: 350px;
        padding-top: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .max {
        display: flex;
        width: 100%;
        max-width: 1400px;
    }

    .impact-full {
        margin-left: 150px;

    }
    .impact-list {
        margin-right: 150px;
    }
    .main-list {
        height: 350px;
        padding-top: 10px;
        width: 500px;
        padding-left: 30px;
    }
}
