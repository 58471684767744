.impact {
    padding: 20px;
}

.impact-text {
    /* background-color:RGBA(236,225,201,0.9); */
    background-color: RGBA(156,132,101,.7);
    padding:30px;
    border-radius:40px;
    text-shadow: 1px 1px grey;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
}

.impact-header {
    font-family: 'Vollkorn SC', serif;
}

.impact a {
    font-family: 'Vollkorn SC', serif;
}

@media screen and (min-width: 320px) {
    .impact-text {
        margin-top: 50px;
        font-size: 18px;
    }

    .arrow-scroll-2 {
        display: flex; 
        justify-content: center;
        margin-top:60px;
        margin-left: 50px;
        margin-bottom: 200px;
    }
}

@media screen and (min-width: 765px) {
    .impact {
        padding-top: 200px;
        padding-bottom: 200px;
    }
    .photo {
        width: 40%;
        margin-right: 0;
        display: inline-block;
    }

    .impact-header {
        font-size: 50px;
    }

    .impact-photo {
        width: 100%;
        margin-left: 15px;
    }

    .impact-text {
        float: right;
        width: 40%;
    }

    .arrow-scroll-2 {
        margin-top: 200px;
    }

}

@media screen and (min-width: 1000px) {
    .impact {
        padding-top: 200px;
        padding-bottom: 420px;
    }
    .impact-header {
        font-size: 70px;
        margin: auto;
    }

    .impact-photo {
        margin-left: 40px;
    }

    .impact-text {
        margin-top:100px;
        width: 50%;
    }

    .arrow-scroll-2 {
        display: flex;
        margin-top:250px;
    }
}


