/* Markup */
  .content {
    margin: auto;
    transform: translate(-50%, -50%);
  }

  /* Arrow & Hover Animation */
  #more-arrows {
    width: 75px;
    height: 65px;
  }
  #more-arrows:hover polygon {
    fill: #FFF;
    transition: all 0.2s ease-out;
  }
  #more-arrows:hover polygon.arrow-bottom {
    transform: translateY(-18px);
  }
  #more-arrows:hover polygon.arrow-top {
    transform: translateY(18px);
  }
  polygon {
    fill: #FFF;
    transition: all 0.2s ease-out;
  }
  polygon.arrow-middle {
    opacity: 0.75;
  }
  polygon.arrow-top {
    opacity: 0.5;
  }

  @media screen and (min-width: 365px) {
    .content {
      display: flex;
      justify-content: center;
    }
  }
  