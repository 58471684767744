.info-slider {
    width: 300px;
    margin: auto;
}

.info-slider p {
    margin: 20px;
}

.slick-dots {
    display: none;
}

.slick-prev, .slick-next {
    top: 15%;
}

@media screen and (min-width: 360px) {
    .slick-prev, .slick-next {
        top: 25%;
    }
}

@media screen and (min-width: 480px) {
    .info-slider {
        width: 425px;
    }
}


@media screen and (min-width: 765px) {
    .info-slider {
        width: 600px;
    }
}

@media screen and (min-width: 1000px) {
    .info-slider {
        width: 500px;
    }
}