footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 10px;
}

.copyright {
    float: left;
}

.build-credit {
    float: right;
}

