.our-story-full {
    background: url(./indiaslums.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute; 
}
 
.our-story-text-full {
    background-color:RGBA(157,132,101,.9);
    box-shadow: 1px 2px 4px RGBA(40,80,37,0.90);
    margin: 10%;
    border-radius: 20px;
}

.our-story-header-full {
    font-size: 85px;
    text-shadow: 3px 3px RGBA(40,80,37,0.90);
    font-family: 'Vollkorn SC', serif;
}

.our-story-full h3 {
    padding:20px;
    border-radius: 5px;
    margin: auto;
    font-size: 40px;
    text-shadow: 2px 3px RGBA(40,80,37,0.70);

}

.our-story-full p {
    background-color:RGBA(157,132,101,.45);
    font-family: 'Montserrat', sans-serif;
    padding: 25px;
    border-radius: 10px;
    font-size: 20px;
}

.catch-line {
    text-align: center;
    text-shadow: 2px 3px RGBA(40,80,37,0.70);
    border-radius: 5px;
    text-decoration: underline;
    font-family: 'Vollkorn SC', serif;
}

.solution-text {
    text-shadow: 2px 3px RGBA(40,80,37,0.70);
    font-size: 40px;
}

@media screen and (min-width: 320px) {
    .our-story-full {
        height: 210%;
    }

    .our-story-text-full {
        margin-top: 100px;
    }
    
    .our-story-header-full {
        font-size: 35px;
        margin: 5%;
    }

    .our-story-full h3 {
        padding: 5px;
        font-size: 20px;
    }

    .our-story-full p {
        font-size: 13px;
    }

    .catch-line {
        font-size: 25px; 
        text-shadow: 2px 2px RGBA(40,80,37,0.70);
    }
}

@media screen and (min-width: 500px) {
    .our-story-full {
        height: 175%;
    }
}

@media screen and (min-width: 765px) {
    .our-story-full {
        height: 155%;
    }

    .our-story-text-full {
        margin: 10%;
    }
    
    .our-story-header-full {
        font-size: 85px;
    }
    
    .our-story-full h3 {
        padding:20px;
        border-radius: 5px;
        margin: auto;
        font-size: 40px;
    }
    
    .our-story-full p {
        padding: 18px;
        border-radius: 10px;
        font-size: 20px;
    }

    .catch-line {
        font-size: 35px; 
        text-shadow: 3px 3px RGBA(40,80,37,0.70);
    }
}

@media screen and (min-width: 1000px) {
    .our-story-full {
        height: 175%;
    }

    .our-story-header-full {
        padding: 0px;
    }
    .our-story-full p {
        padding: 10px;
        font-size: 23px;
    }

    .catch-line {
        font-size: 50px; 
        margin-left: 25%;
        margin-right: 25%;
        text-shadow: 3px 3px RGBA(40,80,37,0.70);

    }
}

@media screen and (min-width: 1400px) {
    .our-story-full {
        height: 210%;
    }

    .our-story-text-full {
        margin-top: 200px;
    }
}
