.our-story {
    padding: 100px;
}
.our-story-text {
    margin:auto;
    font-family: 'Montserrat', sans-serif;
}

.our-story-header, h3, a {
    font-family: 'Vollkorn SC', serif;
}


@media screen and (min-width: 320px) {
    .our-story {
        padding-top:40px;
        padding: 20px;
    }

    .our-story-text {
        /* background-color:RGBA(236,225,201,0.9); */
        background-color: RGBA(156,132,101,.7);
        padding:10px;
        border-radius:40px;
        text-shadow: 1px 1px grey;
        font-size: 16px;
        margin-top: 150px;
    }

    .arrow-scroll-3 {
        display: flex;
        justify-content: center;
        margin-top: 70px;
        margin-left: 50px;
    }
}

@media screen and (min-width: 765px) {
    .our-story {
        padding-top: 150px;
        padding-bottom: 350px;
    }
}

@media screen and (min-width: 1000px) {
    .arrow-scroll-3 {
        display: flex;
        margin-top: 100px;
    }

    .our-story-text {
        font-size: 20px;
        margin-top: 160px;
        padding:40px;
    }
}

