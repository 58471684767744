.inspiration-full {
    position: fixed;
    width: 100%;
    height: 100%;
    /* background: url(./desert-image.jpeg) no-repeat center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.inspiration-text-full {
    width: 80%;
    text-align: center;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inspiration-span {
    font-size: 50px;
}

.photo-inspo {
    display: flex;
    margin: auto;
    width: 50%;
}