.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-top:0;
    font-family: 'Vollkorn SC', serif;

}

 .header-links {
    float: right;
    margin-top:25px;
    text-shadow: 1px 2px RGBA(40,80,37,0.90);
}

.header-links a{
    padding: 5px;
    text-decoration: none;
}

.header-links a:hover{
    cursor: pointer;
    background-color:RGBA(40,80,37,0.64);
    border-radius: 5px;
}

.logo {
    width: 10%;
    margin: 0;
    padding: 10px;
}


@media screen and (min-width: 320px) {
    .logo {
        width:13%;
    }
    .header-links {
        font-size: 14px;
    }
}


@media screen and (min-width: 765px) {
    .header-links {
        font-size: 20px;
    }
}

@media screen and (min-width: 765px) {
    .logo {
        width: 10%;
    }

    .header-links {
        font-size: 30px;
    }
}



